import './GetQuote.css';


const GetQuote = () => {
  return (
    <h2>Get A Quote</h2>
  );
};

export default GetQuote;
